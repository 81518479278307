import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import web3 from "./contracts/initWeb3";
import routes from "./router";
import { SaveAddress } from "./store/user/action";


const AppWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
`

const HeaderWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap}
    width: 100%;
    justify-content: space-between;
`

const BodyWrapper = styled.div`
    width: 100%;
`

export default function App() {

    const dispatch = useDispatch();

    useEffect(() => {
        getAppInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAppInfo = async () => {
        try {
            const address = await web3.eth.getAccounts();
            if (address) {
                dispatch(SaveAddress(address[0]))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <AppWrapper>
            <HeaderWrapper>
                <Header />
            </HeaderWrapper>
            <BodyWrapper>
                {renderRoutes(routes)}
            </BodyWrapper>
            <Footer/>
        </AppWrapper>
    );
    
};
