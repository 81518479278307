import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import news_background from "../../assets/images/news_background.png";
import news_title from "../../assets/images/news_title.png";
import axios from 'axios';
import { Button } from '../nft';

const NewsWapper = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

`
const NewsHeaderStyles = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: row;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`
const NewsShowImages = styled.div`
    width: 868px;
    height: 478px;
    background: url(${news_background}) no-repeat;
    background-size: 100%;
    ${({ theme }) => theme.mediaWidth.upToLarge`
         width: 750px;
        height: 410px;
    `};
`
const NewsShowColor = styled.div`
    width: 332px;
    height: 478px;
    background-color: #26334F;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: none;
    `};
`
const NewsTextStyles = styled.div`
    width: 529px;
    height: 376px;
    box-sizing: border-box;
    padding: 40px 30px;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    background-color: rgba( 255 , 255 , 255 , 0.7 );
    position: absolute;
    top: 50px;
    right: 10px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        top: 20px;
        right:110px;
    `};
    h1{
        font-size: 30px;
        font-weight: bold;
        color: #26334F;
        line-height: 48px;
        margin-top: 30px;
        margin-bottom: 35px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    p{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #26334F;
        line-height: 24px;
        margin-bottom: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
`
const NewDivider = styled.div`
    width: 133px;
    height: 3px;
    background: #FF5A3E;
`
const NewsListStyles = styled.div`
    width: 1200px;
    min-height: 1480px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`
const NewDividerBlue = styled.div`
    min-height: 1480px;
    width: 2px;
    background-color: #26334F;
    position: absolute;
    left: 70px;
    top: 0;
`
const NewsTitle = styled.div`
    width: 131px;
    height: 46px;
    box-sizing: border-box;
    padding-left: 20px;
    background: url(${news_title}) no-repeat;
    background-size: 100%;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 46px;
    position: absolute;
    top: 57px;
    left: 65px;
`
const NewList = styled.div`
    width: 1060px;
    position: absolute;
    left: 40px;
    top: 160px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
         width: 750px;
    `};

`
const NewsBox = styled.div`
    width: 1060px;
    height: 160px;
    background: #FFFFFF;
    margin-bottom: 25px;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 38px 40px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
         width: 680px;
    `};
`
const NewBoxTime = styled.div`
    text-align:center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
         width: 25%;
    `};
    h3{
        font-size: 24px;
        font-weight: bold;
        color: #26334F;
        line-height: 36px;
        padding-top: 10px;
    }
    h6{
        font-size: 14px;
        font-weight: 400;
        color: #26334F;
        line-height: 36px;
    }
`
const NewBoxText = styled.div`
    width: 880px;
    h1{
        font-size: 20px;
        font-weight: 400;
        color: #26334F;
        line-height: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
        &:hover{
            color:#FF5A3E;
        }
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #798194;
        line-height: 24px;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
         width: 750px;
    `};
`
// const NewPaging = styled.div`
//     width: 400px;
//     height: 30px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     box-sizing: border-box;
//     padding: 0px 15px;
//     position: absolute;
//     bottom: 80px;
//     left: 400px;
//     ${({ theme }) => theme.mediaWidth.upToLarge`
//         left: 175px;
//     `};
// `
// const HomePaging = styled.button`
//     width: 50px;
//     height: 26px;
//     border: 1px solid #26334F;
//     outline: none;
//     background-color: transparent;
//     font-size: 14px;
//     font-weight: 400;
//     color: #26334F;
//     cursor: pointer;
// `
// const Pag = styled.div<{active:boolean}>`
//     width: 26px;
//     height: 26px;
//     background: ${({ active }) => (active ? '#26334F' : '#FFF')};
//     color: ${({ active }) => (active ? '#FFFFFF' : '#26334F')};
//     font-size: 14px;
//     font-weight: 400;
//     text-align:center;
//     line-height: 26px;
//     margin-left: 15px;
//     margin-right: 15px;
//     cursor: pointer;
// `
const NewsDetailsStylse = styled.div`
    max-width: 1200px;
    min-height: 800px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px;
    color: #26334F;
    text-align:center;
    h2{
        font-size: 38px;
        padding-bottom: 15px;
    }
    h4{
        text-indent: 36px;
        margin: 10px auto;
        text-align:left;
    }
    h6{
        font-size: 14px;
        font-weight: 300;
        text-align:right;
        line-height: 20px;
        font-family: Microsoft YaHei;
    }

`
const DetailsText = styled.div`
    text-indent: 36px;
    font-size: 16px;
    font-weight: 300;
    color: #798194;
    line-height: 34px;
    margin-top: 10px;
    font-family: Microsoft YaHei;
    text-align:left;
`
const DetailsButton = styled(Button)`
    margin-top: 30px;
`



export default function  News() {


    const { i18n  } = useTranslation();
    const { t } = useTranslation();


    const [list, setList] = useState<any[]>([]);
    const [newsType, setNewsType] = useState(false);
    const [newsDetails, setNewsDetails] = useState<any>({});



    useEffect(() => {
        axios.get('./news/index.json')
        .then(function(res){
            console.log(res.data.list)
            setList(res.data.list)
        })
        .catch(function(error){
            console.log(error)
        })
    }, [])


    const setDetailsClick = (id:number) => {
        setNewsType(true);
        for(var i = 0; i < list.length; i++) {
            if(id === list[i].id){
                setNewsDetails(list[i])
                console.log(list[i]);
            }
        }
    }

    if(newsType){
        return(
            <NewsDetailsStylse>
                <h2>{newsDetails ? i18n.language === 'en' ? newsDetails.title_en : newsDetails.title_zh : ''}</h2>
                <h6>{newsDetails.author}</h6>
                <h6>{newsDetails.time_year}-{newsDetails.time_day}</h6>
                <DetailsText>
                    {
                        i18n.language === 'en' ? newsDetails.text_en.map((item:any, index:any) => {
                            return (
                                <p key={index}>{item}</p>
                            )
                        }):newsDetails.text_zh.map((item:any, index:any) => {
                            return (
                                <p key={index}>{item}</p>
                            )
                        })
                    }
                </DetailsText>
                <h4>
                    Download:&nbsp;
                    <a href={newsDetails.file_path} target="_blank">{newsDetails.file_path}</a>
                </h4>
                <DetailsButton onClick={() => setNewsType(false)}>{t('Button_Back')}</DetailsButton>
            </NewsDetailsStylse>
        )
    }else{
        return (
            <NewsWapper>
                <NewsHeaderStyles>
                    <NewsShowImages />
                    <NewsShowColor />
                    <NewsTextStyles>
                        <NewDivider />
                        <h1>{list.length > 0 ? i18n.language === 'en' ? list[0].title_en : list[0].title_zh : ''}</h1>
                        <p>{list.length > 0 ? i18n.language === 'en' ? list[0].text_en[0] : list[0].text_zh[0] : ''}</p>
                        <NewDivider />
                        <p style={{marginTop:'20px'}}>{t('source')}{list.length > 0 ? list[0].author : ''}</p>
                    </NewsTextStyles>
                </NewsHeaderStyles>
                <NewsListStyles>
                    <NewDividerBlue />
                    <NewsTitle>{t('News')}</NewsTitle>
                    <NewList>
                        {
                            list.length > 0 &&
                            list.map((item, index) => {
                                return (
                                    <NewsBox key={item.id}>
                                        <NewBoxTime>
                                            <h3>{item.time_day}</h3>
                                            <h6>{item.time_year}</h6>
                                        </NewBoxTime>
                                        <NewBoxText onClick={ ()=> setDetailsClick(item.id)}>
                                            <h1>{i18n.language === 'en' ? item.title_en : item.title_zh }</h1>
                                            <p>{i18n.language === 'en' ? item.text_en[0] : item.text_zh[0]}</p>
                                        </NewBoxText>
                                    </NewsBox>
                                )
                            })
                        }
                    </NewList>
                    {/* <NewPaging>
                        <HomePaging>首页</HomePaging>
                        <Pag active={true}>1</Pag>
                        <Pag active={false}>2</Pag>
                        <Pag active={false}>3</Pag>
                        <Pag active={false}>4</Pag>
                        <Pag active={false}>5</Pag>
                        <HomePaging>尾页</HomePaging>
                    </NewPaging> */}
                </NewsListStyles>
            </NewsWapper>
        )
    }
}
