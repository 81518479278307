import React, { useState } from 'react'
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
import SetLanguage from '../SetLanguage';
import logo from '../../assets/images/logo.png'
import { useTranslation } from 'react-i18next';
import { useViewport } from '../ViewportProvider';

const HeaderWapper = styled.div`
    width: 100%;
    background-color: #FFF;
`

const HeaderFrame = styled.div`
    width: 1200px;
    padding: 15px 0px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 100%;
        padding: 15px 40px;
    `};
    
`
const HeaderTitle = styled.div`
    display: flex;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #26334F;
    line-height: 55px;
    img{
        vertical-align: middle;
        border-style: none;
        margin-right:10px;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
        font-size: 24px;
    `};
`
const HeaderLinks = styled.div` 
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: flex;
        flex-direction: column;
    `};
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: #26334F;
    padding:15px 5px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 400;
    text-align:center;
    :hover,
    :focus {
        color: #26334F;
        font-weight: bold;
        font-size: 16px;
        text-decoration: none;
        border-bottom:2px solid #FF5A3E;
    }
    &.${activeClassName} {
        font-size: 16px;
        color: #26334F;
        font-weight: bold;
        text-decoration: none;
        border-bottom:2px solid #FF5A3E;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
        font-size: 20px;
    `};
`
const NavStyles = styled.div`
    display: flex;
    flex-direction: column;
    span{
        display: block;
        width:50px;
        height: 4px;
        background: #FF5A3E;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`
const NavModel = styled.div<{active:boolean}>`
    width: 200px;
    height: 400px;
    box-sizing: border-box;
    padding-top: 25px;
    background: #FFF;
    position: absolute;
    top: 84px;
    right: 0;
    z-index: 99;
    display: ${({active }) => (active ? 'block' : 'none')};
`
const HeaderMiniFlex = styled.div`
    width: 170px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default function Header() {
    
    const { t } = useTranslation();
    const {width} = useViewport();
    const [isOpen, setIsOpen] = useState(false)

   

    if(width < 1200){
        return (
            <HeaderWapper>
                <HeaderFrame>
                    <HeaderTitle>
                        <img width={'55px'} height={'55px'}  src={logo} alt='Logo' />
                        <span>{t('Name')}</span>
                    </HeaderTitle>
                    <HeaderMiniFlex>
                        <SetLanguage />
                        <NavStyles onClick={() => setIsOpen(!isOpen)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </NavStyles>
                    </HeaderMiniFlex>
                </HeaderFrame>
                <NavModel active={isOpen}>
                    <HeaderLinks>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/home'}>{t('Home')}</StyledNavLink>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/business'}>{t('Business')}</StyledNavLink>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/news'}>{t('News')}</StyledNavLink>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/nftIntroduce'}>{t('Introduction')}</StyledNavLink>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/nft'}>{t('NFT')}</StyledNavLink>
                        <StyledNavLink onClick={() => setIsOpen(false)} to={'/about'}>{t('About')}</StyledNavLink>
                    </HeaderLinks>
                </NavModel>
            </HeaderWapper>
        )
    }else{
        return (
            <HeaderWapper>
                <HeaderFrame>
                    <HeaderTitle>
                        <img width={'55px'} height={'55px'}  src={logo} alt='Logo' />
                        <span>{t('Name')}</span>
                    </HeaderTitle>
                    <HeaderLinks>
                        <StyledNavLink to={'/home'}>{t('Home')}</StyledNavLink>
                        <StyledNavLink to={'/business'}>{t('Business')}</StyledNavLink>
                        <StyledNavLink to={'/news'}>{t('News')}</StyledNavLink>
                        <StyledNavLink to={'/nftIntroduce'}>{t('Introduction')}</StyledNavLink>
                        <StyledNavLink to={'/nft'}>{t('NFT')}</StyledNavLink>
                        <StyledNavLink to={'/about'}>{t('About')}</StyledNavLink>
                    </HeaderLinks>
                    <SetLanguage />
                </HeaderFrame>
            </HeaderWapper>
        )
    }
}
