import * as actionTypes from "./constants";

const initState = {
    isLoading : {
        isOpen : false,
        title : ''
    },
    address:'',
    securityNumber:'',
}

export default function reducer(state = initState,action: any){

	const {type} = action
    switch (type) {
        case actionTypes.SET_LOADING:
            return {...state,  isLoading: action.isLoading}
        case actionTypes.SAVE_ADDRESS:
            return {...state,  address: action.address}
        case actionTypes.SAVE_SECURIT_NUMBER:
            return {...state,  securityNumber: action.securityNumber}
        default:
            return state
    }
}