import web3 from "../contracts/initWeb3";


/**
 * 小数点后18位
 * 将单位 wei 的数值转换为整数,并保留小数点后4位
 *  @param {string} value
 */
const toolFromWei = (value: string | import("bn.js")) => {
  const valueEther = web3.utils.fromWei(value, 'ether');
  var re = /([0-9]+\.[0-9]{2})[0-9]*/;
  const valueNumber = valueEther.replace(re, "$1");
  return valueNumber;
}


/**
 * 时间戳转换时间格式 2000-01-01 00:00:00
 * @param {number} timeStamp 时间戳
 */
const conversionDate = (timeStamp: number) => {
  if (timeStamp > 0) {
    var date = new Date(timeStamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '年';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return Y + M + D + '日'; //时分秒可以根据自己的需求加上
  }
  return '0000-00-00';
};


function CurentTime() {
  var now = new Date();
  var year = now.getFullYear();       //年
  var month = now.getMonth() + 1;     //月
  var day = now.getDate();            //日
  var hh = now.getHours();            //时
  var mm = now.getMinutes();          //分
  var clock = year + "-";

  if (month < 10)
    clock += "0";

  clock += month + "-";

  if (day < 10)
    clock += "0";

  clock += day + "T";

  if (hh < 10)
    clock += "0";

  clock += hh + ":";
  if (mm < 10) clock += '0';
  clock += mm;
  return (clock);
}


//阿拉伯数字转换成大写汉字
function numberParseChina(money: any) {
  //汉字的数字
  var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  //基本单位
  var cnIntRadice = ['', '拾', '佰', '仟'];
  //对应整数部分扩展单位
  var cnIntUnits = ['', '万', '亿', '兆'];
  //对应小数部分单位
  var cnDecUnits = ['角', '分', '毫', '厘'];
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = '';
  //分离金额后用的数组，预定义
  var parts;
  if (money === '') { return ''; }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money === 0) {
    chineseStr = cnNums[0];
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf('.') === -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += '';
  }
  //小数部分
  if (decimalNum !== '') {
    var decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0];
  } else if (decimalNum === '') {
    chineseStr += '';
  }
  return chineseStr;
}


const giveAwayBehalf = (typeId: string, amount: string) => {
  if (typeId === '0') {
    const a = Math.floor(Number(amount) / 1000) ;
    const b = a * 20000;
    return b
  } else if(typeId === '1') {
    const a = Math.floor(Number(amount) / 50000) ;
    const b = a * 10000;
    return b
  }
};



export {
  toolFromWei,
  conversionDate,
  numberParseChina,
  CurentTime,
  giveAwayBehalf,
}
