import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AppBackgroundBody from '../../components/AppBackgroundBody';
import chaxun from '../../assets/images/chaxun.png';
import AssetShowImages from '../../components/AssetShowImages';

import { Swiper, SwiperSlide } from "swiper/react";
import domtoimage from 'dom-to-image';

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "./styles.css";

import SwiperCore, {
    Pagination, Navigation
} from 'swiper/core';
import Model from '../../components/Model';
import { useTranslation } from 'react-i18next';
import { useWallet } from 'use-wallet';
import { useDispatch, useSelector } from 'react-redux';
import web3 from '../../contracts/initWeb3';
import { ContractPool } from '../../contracts';
import { conversionDate, CurentTime, numberParseChina } from '../../utils/tool';
import { exportWord } from 'mhtml-to-word';
import { SaveAddress, SaveSecurityNumber } from '../../store/user/action';
import { HTTP_URL } from '../../contracts/constant';

SwiperCore.use([Pagination, Navigation]);

const NftWapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 140px 20px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        padding: 200px 0px;
    `};
`
const NftTitle = styled.h1`
    color: #fff;
    font-size: 36px;
    margin-bottom: 50px;
`

const InputFlex = styled.div`
    width: 1200px;
    height: 170px;
    background: rgb(255,255,255,0.1);
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`
const InputStyles = styled.input`
    width: 1138px;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    text-align:center;
    font-size: 16px;
    color: #26334F;
`
const IconSubmit = styled.div`
    width:40px;
    height: 40px;
    background: url(${chaxun}) no-repeat center;
    background-size: 50%;
    position: absolute;
    right: 50px;
    cursor: pointer;
`

const SwiperStyles = styled.div`
    width: 660px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 99;
`
const AssetsNumber = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
`
const AssetsButtonFlex = styled.div`
    display: flex;
    flex-direction: row;
`
const UpdateButton = styled.div`
    width: 110px;
    height: 36px;
    background: #3360C2;
    border-radius: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align:center;
    line-height: 36px;
    cursor: pointer;
    margin-left: 15px;
`

const AddButton = styled(UpdateButton)`
    background: #FF5A3E;
`
const QueryButton = styled(UpdateButton)`
    background: #0180b0;
`

const AssetsUrl = styled.div`
    display: flex;
    flex-direction: row;
    color:#E7EAF4;
    font-size: 16px;
    p{
        padding: 0px 60px 0 20px;
        text-decoration:underline;
        cursor: pointer;
    }
`
const Title = styled.h1`
    font-size: 28px;
    color: #000;
    text-align: center;


`
const ModelFrom = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
`
const ModelText = styled.div`
    padding: 30px;
    font-size: 14px;
    color:#000;
    background: #fff;
    line-height: 28px;
    h3{
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;
    }
    span{
        text-decoration:underline;
    }
    .interval_4{
        text-indent:40px
    }
    .interval_2{
        text-indent:28px
    }
    .interval_right{
        text-align: right;
    }
    .seal_1{
        position: absolute;
        left: 130px;
        bottom: 130px;
    }
    .seal_2{
        position: absolute;
        left: 250px;
        bottom: 130px;
    }
`
const ModelTextButton = styled.div`
    text-align: center;
    padding: 20px 0;
`
const From = styled.div`
    font-size: 16px;
    color: #E7EAF4;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 40px 20px 0px;
    select{
        width: 82%;
        height: 40px;
        background-color: transparent;
        border: 1px solid #E7EAF4;
        color: #E7EAF4;
        border-radius: 5px;
        padding: 0px 20px;
    }
`
const Label = styled.label`
    width: 25%;
    text-align: right;
`
const Input = styled.input`
    width: 75%;
    height: 40px;
    background-color: transparent;
    border: 1px solid #E7EAF4;
    color: #E7EAF4;
    border-radius: 5px;
    padding: 0px 20px;
    &:hover{
        border:1px solid #FF5A3E;
    }
`
const AssetButtonStyles = styled.div`
    width: 530px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
export const Button = styled.button`
    width: 158px;
    height: 40px;
    background: #FF5A3E;
    border-radius: 20px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    text-align: center;
    line-height: 40px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 450px;

    `};
`
const ButtonCancel = styled(Button)`
    background: transparent;
    border:1px solid #FF5A3E;
    color:#FF5A3E;
`
const LoginButton = styled.button`
    width: 290px;
    height: 50px;
    border: 1px solid #FF5A3E;
    border-radius: 25px;
    background-color: transparent;
    color: #FF5A3E;
    font-size: 16px;
    cursor: pointer;
    margin-top: 100px;
`

declare const window: any;

export default function NFT() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const wallet = useWallet();
    const activate = (connector: "injected" | "walletconnect") => wallet.connect(connector);
    const myAddress = useSelector((state: any) => state.userInfo.address);
    const mySecurityNumber = useSelector((state: any) => state.userInfo.securityNumber);

    const [isNumber, setIsNumber] = useState(false); //判断是否获取身份证号等
    const [isOpen, setIsOpen] = useState(false);  //打开窗口
    const [isUpdateWindow, setIsUpdateWindow] = useState(false); //判断更正和新增窗口
    const [isRole, setIsRole] = useState(false); //判断管理员
    const [isAgreement, setIsAgreement] = useState(false); //打开窗口
    const [isSubscribe, setIsSubscribe] = useState(false); //

    const inputSocialSecurityNumber = useRef<HTMLInputElement>(null); //获取输入框身份证号

    const inputAddress = useRef<HTMLInputElement>(null);
    const inputMobile = useRef<HTMLInputElement>(null);
    const inputName = useRef<HTMLInputElement>(null);
    const inputNumber = useRef<HTMLInputElement>(null);
    const inputPrice = useRef<HTMLInputElement>(null);
    const inputDateOfCreation = useRef<HTMLInputElement>(null);
    const [inputCode, setInputCode] = useState('CN');
    const [inputTypeId, setInputTypeId] = useState('0');

    const [swiperId, setSwiperId] = useState(0); //当前选中的证书
    const [ownersData, setOwnersData] = useState({
        name:'',
        socialSecurityNumber:'',
        countryCode:'CN',
        mobile:'',
        tokens:[],
    });
    const [sharesData, setSharesData] = useState<any[]>([]);

    useEffect(() => {
        if (!isUpdateWindow) {
            inputDateOfCreation.current!.value = CurentTime();
        }
    },[isUpdateWindow])

    useEffect(() => {
        if(myAddress){
            hasRoleClick()
        }
        // eslint-disable-next-line
    },[myAddress])

    useEffect(() => {
        if(mySecurityNumber){
            getTokenOfOwner()
        }
        // eslint-disable-next-line
    }, [mySecurityNumber])


    //判断是否为管理员
    const hasRoleClick = async () =>{
        const role = await ContractPool.methods.MANAGER_ROLE().call();
        if(role){
           var isRole = await ContractPool.methods.hasRole(role, myAddress).call();
           setIsRole(isRole);
        }
    };

    const getSocialSecurityNumberClick = () => {
        const securityNumber = inputSocialSecurityNumber.current!.value.trim();
        if(securityNumber){
            dispatch(SaveSecurityNumber(securityNumber));
        }else{
            alert('')
        }
    }

    //获取相关信息
    const getTokenOfOwner = async () =>{
        try {
            const data = await ContractPool.methods.owners(mySecurityNumber).call();
            if(data.tokens.length > 0) {
                data.tokens = data.tokens.filter(function(val:any){
                    return val > 0;
                });
                setOwnersData(data);
                setIsNumber(true);
                const sharesList = [];
                for (var i=0;i< data.tokens.length;i++){
                    try {
                        const sharesData = await ContractPool.methods.shares(data.tokens[i]).call();
                        if (sharesData && sharesData.amount > 0) {
                            sharesList.push(sharesData)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                setSharesData(sharesList);
            } else {
                alert('暂无证书或输入数据错误，请重新输入查询！')
            }
        } catch (error) {
            alert('RPC 节点连接错误，请更换网络环境后再试！')
            console.log(error);
        }
    }

   //生成图片
    const getJpeg = (id: number) => {
        const node = document.getElementById("node_" + (id + 1));
        domtoimage.toJpeg(node, { quality: 2 })
            .then((defaultUrl: string) => {
                var link = document.createElement('a');
                link.download = '股权证.jpeg';
                link.href = defaultUrl;
                link.click();
            });
    }

    //打开新增窗口
    const isCreateClick = () => {
        if(isRole){
            inputName.current!.value = '';
            inputMobile.current!.value = '';
            inputNumber.current!.value = '';
            setIsUpdateWindow(false);
            setIsOpen(true);
        }else{
            alert('您无权操作，请通过身份证号查询！')
        }
    }

    //打开更正窗口
    const isUpdateClick = () =>{
        setIsUpdateWindow(true);
        inputName.current!.value = ownersData.name;
        inputMobile.current!.value = ownersData.mobile;
        inputNumber.current!.value = ownersData.socialSecurityNumber;
        setInputCode(ownersData.countryCode);
        setIsOpen(true);
    }

    //新增
    const createClick = () => {
        const address = inputAddress.current!.value.trim();
        const name = inputName.current!.value.trim();
        const mobile = inputMobile.current!.value.trim();
        const code = inputCode;
        const number = inputNumber.current!.value.trim();
        const typeId = inputTypeId;
        const price = inputPrice.current!.value.trim();
        const time = inputDateOfCreation.current!.value.trim();
        const dateOfCreation = Date.parse(time) / 1000;
        const isAddress = web3.utils.isAddress(address);
        if (!isAddress) {
            alert(t('NFT_Hint_3'))
            return;
        }
        if (!name) {
            alert(t('NFT_Hint_4'))
            return;
        }
        if (!mobile) {
            alert(t('NFT_Hint_5'))
            return;
        }
        if (!number) {
            alert(t('NFT_Hint_6'))
            return;
        }
        if (!price) {
            alert(t('NFT_Hint_7'))
            return;
        }
        ContractPool.methods.createToken(name, code, number, mobile, typeId, price, dateOfCreation)
            .send({ from: myAddress })
            .on('transactionHash', function (hash: any) {
                setIsOpen(false);
                alert(t('NFT_Hint_8'));
            })
            .on('receipt', function (receipt: any) {
                alert(t('NFT_Hint_9'));

            })
            .on('error', function (error: any, receipt: any) {
                setIsOpen(false);
                alert(t('NFT_Hint_10'));
            })
    }

    //更正
    const updateClick = () =>{
        const name = inputName.current!.value.trim();
        const mobile = inputMobile.current!.value.trim();
        const countryCode = inputCode;
        const socialSecurityNumber = inputNumber.current!.value.trim();
        if(!name){
            alert(t('NFT_Hint_4'))
            return;
        }
        if(!mobile){
            alert(t('NFT_Hint_5'));
            return;
        }
        if(!socialSecurityNumber){
            alert(t('NFT_Hint_6'));
            return;
        }
        ContractPool.methods.updateOwner(socialSecurityNumber,name,countryCode,mobile)
        .send({from:myAddress})
        .on('transactionHash',function(hash: any){
            setIsOpen(false);
            alert(t('NFT_Hint_8'));
        })
        .on('receipt',function(receipt: any){
            alert(t('NFT_Hint_9'));
            getTokenOfOwner();
            hasRoleClick();
        })
        .on('error',function(error: any,receipt: any){
           setIsOpen(false);
           alert(t('NFT_Hint_10'));
        })
    }

    const removeToken = (tokenId:number) => {
        ContractPool.methods.removeToken(tokenId)
        .send({from:myAddress})
        .on('transactionHash',function(hash: any){
            setIsOpen(false);
            alert(t('NFT_Hint_8'));
        })
        .on('receipt',function(receipt: any){
            alert(t('NFT_Hint_9'));
            getTokenOfOwner();
            hasRoleClick();
        })
        .on('error',function(error: any,receipt: any){
        setIsOpen(false);
        alert(t('NFT_Hint_10'));
        })
    }

    const setTypeName = (typeId:string) => {
        if(typeId === '0'){
            return '代持'
        }else if(typeId === '1'){
            return '分红'
        }
    }

    //下载
    const exportWordClick = (selector:string,name:string) => {
        exportWord({
            filename: name,
            selector: selector,
            style:'h1{width:100%;font-size: 24px;text-align: center;}p{font-size: 16px;}span{text-decoration:underline;}.interval_4{text-indent:40px}.interval_2{text-indent:40px}.interval_right{text-align: right;}.seal_1{width:100%;position: absolute;left: 250px;bottom: 130px;}'
        })
    }

    //钱包链接
    const injectedClick = async () =>{
        try {
            const chainId = window.ethereum.networkVersion;
            // eslint-disable-next-line eqeqeq
            if(chainId == wallet.chainId){
                activate('injected')
                window.ethereum.on('accountsChanged', function (accounts: any[]) {
                    if(accounts.length > 0){
                        dispatch(SaveAddress(accounts[0]));
                        console.log(accounts[0]);
                    }
                });
            }else{
                alert(t('NFT_Hint_1'))
            }
        } catch (error) {
            alert(t('NFT_Hint_2'))
        }
    };

    const delNumberClick = () =>{
        dispatch(SaveSecurityNumber(''));
        setIsNumber(false);
    }

    if (isNumber) {
        return (
            <AppBackgroundBody height='860px'>
                <NftWapper>
                    <SwiperStyles>
                        <AssetsNumber>共{sharesData.length}张证书，当前</AssetsNumber>
                        <AssetsButtonFlex>
                            <QueryButton onClick={delNumberClick}>查询</QueryButton>
                            {
                                 myAddress ?  <>
                                    {
                                        isRole && <UpdateButton onClick={isUpdateClick}>更正</UpdateButton>
                                    }
                                    {
                                        isRole && <AddButton onClick={isCreateClick}>新增</AddButton>
                                    }
                                  </> : <AddButton onClick={injectedClick}>链接钱包</AddButton>
                            }
                        </AssetsButtonFlex>
                    </SwiperStyles>
                    <Swiper
                        pagination={{ "type": "fraction" }}
                        navigation={true}
                        className="mySwiper"
                        onSlideChange={(e:any) => setSwiperId(e.activeIndex)}
                    >
                        {
                            sharesData.length > 0 && sharesData.map((item:any, index:any) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <AssetShowImages
                                            id={index+1}
                                            tokenId={ownersData.tokens[swiperId]}
                                            name={ownersData.name}
                                            socialSecurityNumber={ownersData.socialSecurityNumber}
                                            amount={item.amount}
                                            typeId={item.typeId}
                                            price={item.price}
                                            dateOfCreation={item.dateOfCreation}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <AssetsUrl>
                        {
                            isRole && <p onClick={() => removeToken(ownersData.tokens[swiperId])}>删除</p>
                        }
                        {
                            sharesData.length > 0 && sharesData[swiperId].typeId === '2' ? '' : <><p onClick={() => setIsAgreement(true)}>股权委托代持协议</p><p onClick={() => setIsSubscribe(true)}>股权认购承诺书</p></>
                        }
                        <p onClick={() => getJpeg(swiperId)}>下载证书</p>
                    </AssetsUrl>
                </NftWapper>
                <Model isOpen={isOpen}>
                    <Title>{isUpdateWindow ? '更正' : '新增'}</Title>
                    <ModelFrom>
                        {
                            !isUpdateWindow && <From>
                                <Label>钱包地址：</Label>
                                <Input type='text' ref={inputAddress} />
                            </From>
                        }
                        <From>
                            <Label>姓名：</Label>
                            <Input type='text' ref={inputName}/>
                        </From>
                        <From>
                            <Label>国家代码：</Label>
                            <select value={inputCode} onChange={(e) => setInputCode(e.target.value)}>
                                <option value="CN">CN</option>
                                <option value="US">US</option>
                            </select>
                        </From>
                        <From>
                            <Label>社会安全号码：</Label>
                            <Input type='text' ref={inputNumber} />
                        </From>
                        <From>
                            <Label>电话号码</Label>
                            <Input type='text' ref={inputMobile} />
                        </From>
                        {
                            !isUpdateWindow && <From>
                                <Label>类型：</Label>
                                <select value={inputTypeId} onChange={(e) => setInputTypeId(e.target.value)}>
                                    <option value="0">代持</option>
                                    <option value="1">分红</option>
                                </select>
                            </From>
                        }
                        {
                            !isUpdateWindow && <From>
                                <Label>价格：</Label>
                                <Input type='text' ref={inputPrice} />
                            </From>
                        }
                        {
                            !isUpdateWindow &&  <From>
                                <Label>创建日期：</Label>
                                <Input type='datetime-local' ref={inputDateOfCreation} />
                            </From>
                        }
                        <AssetButtonStyles >
                            <ButtonCancel onClick={() => setIsOpen(false)}>取消</ButtonCancel>
                            <Button onClick={isUpdateWindow ? updateClick : createClick}>确定</Button>
                        </AssetButtonStyles>
                    </ModelFrom>
                </Model>
                <Model isOpen={isAgreement}>
                    {
                        sharesData.length > 0 && <ModelText className='entrust_1'>
                            <Title>股权委托{setTypeName(sharesData[swiperId].typeId)}协议</Title>
                            <p className='interval_right'>认证编号： {ownersData.tokens[swiperId]}</p>
                            <p>甲方（委托人）：<span>{ownersData.name}</span>  &emsp;身份证号：<span>{ownersData.socialSecurityNumber}</span></p>
                            <p>乙方（委托人）：四川智天金融服务外包有限公司 </p>
                            <p className='interval_4'>董事长 : 邓智天 &emsp;&emsp; 身份证号：510824196702285998</p>
                            <p className='interval_2'>经双方平等协商，根据《公司法》及其它法律法规的有关规定，甲乙双方自愿达成如下股权委托代持协议：</p>
                            <p className='interval_2'>甲方因为投资额度不能达到显名股东要求的额度，特委托乙方代为持有“四川智天金融服务外包有限公司”股权：<span>{ numberParseChina(Number(sharesData[swiperId].typeId) > 0 && Number(ownersData.tokens[swiperId]) >= 227290 ? Number(sharesData[swiperId].amount) * 2.5 : Number(sharesData[swiperId].amount)) }</span> 股。股价：1.00元/股，金额 ¥ <span>{sharesData[swiperId].price}</span> 元，人民币大写：<span>{numberParseChina(Number(sharesData[swiperId].price))}</span>  元。</p>
                            {
                                sharesData[swiperId].typeId === '1' ? <p>待公司上市主板后，按照上市公司利润，每年进行分红。</p> : <p className='interval_2'>待公司上市主板后，按照上市公司市值计算，除去预付款 ¥ <span>{sharesData[swiperId].price}</span> 元后，应归还公司现金 ¥ <span>{ Number(sharesData[swiperId].amount) - Number(sharesData[swiperId].price) - Number(sharesData[swiperId].price) * (ownersData.tokens[swiperId] >= 130000 ? 100 : 20) }</span> 元，人民币大写：<span>{numberParseChina(Number(sharesData[swiperId].amount) - Number(sharesData[swiperId].price) - Number(sharesData[swiperId].price) * (ownersData.tokens[swiperId] >= 130000 ? 100 : 20))}</span> 元。剩余部分属代持人 <span>{ownersData.name}</span> 所有。</p>
                            }
                            <p className='interval_4'>{setTypeName(sharesData[swiperId].typeId)}股权委托董事长邓智天行使股东权利和义务。</p>
                            <p className='interval_4'>此协议甲乙双方签字或盖章之日起生效</p>
                            <p>甲方（委托人）: </p>
                            <p>乙方（委托人）: 四川智天金融服务外包有限公司 </p>
                            <p className='interval_4'>董事长 : 邓智天 </p>
                            <img className='seal_1' src={`${HTTP_URL}images/seal_1.png`} alt="" width='50'/>
                            <img className='seal_2' src={`${HTTP_URL}images/seal_2.png`} alt="" width='100'/>
                            <p className='interval_right'>{conversionDate( Number(sharesData[swiperId].dateOfCreation))}</p>
                        </ModelText>
                    }
                    <ModelTextButton style={{backgroundColor:'#fff'}}>
                        <Button onClick={ () => exportWordClick(".entrust_1",'股权委托代持协议')}>下载</Button>
                        <Button onClick={() => setIsAgreement(false)}>关闭</Button>
                    </ModelTextButton>
                </Model>
                <Model isOpen={isSubscribe}>
                    {
                        sharesData.length > 0 && <ModelText className='entrust_2'>
                            <Title >股权认购承诺书</Title>
                            <p className='interval_right'>认证编号： {ownersData.tokens[swiperId]}</p>
                            <p className='interval_2'>鉴于：四川智天金融服务外包有限公司（以下简称公司）为依照《中华人民共和国公司法》和有关法律法规成立并存续的公司。公司已于2015年12月6日在深圳前海股权交易中心挂牌，并取得上主板的智媒“直通车”。公司拟定了3至4年上主板市场的战略规划。</p>
                            <p className='interval_2'>•	股东在上主板前，采用线下股权转让方式进行交易。交易价格由双方自行商定，符合国家法律法规。在主板上市后，股东凭股权证换领智天金融股票，按证券交易所规则进行交易。</p>
                            <p className='interval_2'>•	本人自愿认购公司股权 <span>{ numberParseChina(Number(sharesData[swiperId].typeId) > 0 && Number(ownersData.tokens[swiperId]) >= 227290 ? Number(sharesData[swiperId].amount) * 2.5 : Number(sharesData[swiperId].amount)) }</span> 股（股价：1.00/股），预付定金 ¥ <span>{sharesData[swiperId].price}</span> 元。金额大写：<span>{numberParseChina(Number(sharesData[swiperId].price))}</span> 元整。</p>
                            <p className='interval_4'>•	本人 <span>{ownersData.name}</span> 清晰地知晓股权投资与其他投资一样具有风险，不可退股并且没有任何利息，同时依照“公司法”规定享受出资人（股东）的权利并承担相应义务。</p>
                            <p className='interval_4'>•	本人自愿将投票权委托公司法人代表邓智天先生行使。</p>
                            <p className='interval_4'>•	本协议一式两份，签字盖章即生效，双方各持一份，具有同等法律效力。公司内部复印5份，董事长1份，总裁办1份，总经理1份，行政办1份，财务部1份。</p>
                            <p>认购代表（签章）：</p>
                            <p>联系电话：{ownersData.mobile}  &emsp; 四川智天金融服务外包有限公司</p>
                            <img className='seal_1' src={`${HTTP_URL}images/seal_1.png`} alt="" width='50'/>
                            <img className='seal_2' src={`${HTTP_URL}images/seal_2.png`} alt="" width='100'/>
                            <p>经办人：</p>
                            <p className='interval_right'> {conversionDate( Number(sharesData[swiperId].dateOfCreation))}</p>
                        </ModelText>
                    }
                    <ModelTextButton>
                        <Button onClick={ () => exportWordClick(".entrust_2",'股权认购承诺书')}>下载</Button>
                        <Button onClick={() => setIsSubscribe(false)}>关闭</Button>
                    </ModelTextButton>
                </Model>
            </AppBackgroundBody>
        )
    } else {
        return (
            <AppBackgroundBody height='860px'>
                <NftWapper>
                    <NftTitle>NFT证书查询</NftTitle>
                    <InputFlex>
                        <InputStyles type='text' placeholder='输入身份证号码或社会安全号码查询证书' ref={inputSocialSecurityNumber}/>
                        <IconSubmit onClick={getSocialSecurityNumberClick}/>
                    </InputFlex>
                    <LoginButton onClick={ myAddress ? isCreateClick : injectedClick}>{myAddress ? "新增证书" : "链接钱包"}</LoginButton>
                </NftWapper>
                <Model isOpen={isOpen}>
                    <Title>{isUpdateWindow ? '更正' : '新增'}</Title>
                    <ModelFrom>
                        {
                            !isUpdateWindow && <From>
                                <Label>钱包地址：</Label>
                                <Input type='text' ref={inputAddress} value={myAddress} />
                            </From>
                        }
                        <From>
                            <Label>姓名：</Label>
                            <Input type='text' ref={inputName} />
                        </From>
                        <From>
                            <Label>国家代码：</Label>
                            <select value={inputCode} onChange={(e) => setInputCode(e.target.value)}>
                                <option value="CN">CN</option>
                                <option value="US">US</option>
                            </select>
                        </From>
                        <From>
                            <Label>社会安全号码：</Label>
                            <Input type='text' ref={inputNumber} />
                        </From>
                        <From>
                            <Label>电话号码：</Label>
                            <Input type='text' ref={inputMobile} />
                        </From>
                        {
                            !isUpdateWindow && <From>
                                <Label>类型：</Label>
                                <select value={inputTypeId} onChange={(e) => setInputTypeId(e.target.value)}>
                                    <option value="0">代持</option>
                                    <option value="1">分红</option>
                                </select>
                            </From>
                        }
                        {
                            !isUpdateWindow && <From>
                                <Label>价格：</Label>
                                <Input type='text' ref={inputPrice} />
                            </From>
                        }
                        {
                            !isUpdateWindow &&  <From>
                                <Label>创建日期：</Label>
                                <Input type='datetime-local' ref={inputDateOfCreation} />
                            </From>
                        }
                        <AssetButtonStyles >
                            <ButtonCancel onClick={() => setIsOpen(false)}>取消</ButtonCancel>
                            <Button onClick={isUpdateWindow ? updateClick : createClick}>确定</Button>
                        </AssetButtonStyles>
                    </ModelFrom>
                </Model>
            </AppBackgroundBody>
        )
    }
}
