import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import AppBackgroundBody from '../../components/AppBackgroundBody';

import about_show_1 from "../../assets/images/about_show_1.png";
import business_background from "../../assets/images/business_background.png";
import icon_1 from "../../assets/images/icon_1.png";
import icon_2 from "../../assets/images/icon_2.png";
import icon_3 from "../../assets/images/icon_3.png";
import icon_4 from "../../assets/images/icon_4.png";
import icon_5 from "../../assets/images/icon_5.png";
import icon_6 from "../../assets/images/icon_6.png";
import news_show_1 from "../../assets/images/news_show_1.png";
import news_show_icon_1 from "../../assets/images/news_show_icon_1.png";





import BusinessBoxShow from '../../components/BusinessBoxShow';
import { useTranslation } from 'react-i18next';
import axios from 'axios';






const HomeWapper = styled.div`
    width: 100%;
`
const HeaderPublicity = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const DeclarationText = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 300px;
    text-align:center;
    h1{
        font-size: 48px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 48px;
        margin-bottom: 60px;
    }
    p{
         width: 470px;
        font-size: 18px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 36px;
    }
`
const DeclarationNavLink = styled(NavLink)`
    width: 290px;
    height: 50px;
    border: 1px solid #FF5A3E;
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
    color: #FF5A3E;
    line-height: 50px;
    margin-top: 40px;
    :hover,
    :focus {
        font-size: 18px;
        color: #FF5A3E;
        text-decoration: none;
    }
`

const NewsColorBackground = styled.div`
    width: 100%;
    height: 200px;
    background: #1A253E;
`
const NewsStyles = styled.div`
    width: 1200px;
    height: 280px;
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`
const NewsBoxLeft = styled.div`
    width: 384px;
    height: 280px;
    background: #26334F;
    border-radius: 10px;
    position: relative;
    p{
        height: 40px;
        padding: 25px 15px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: none;
    `};
`
const NewsNavLink = styled(NavLink)`
    outline: none;
    cursor: pointer;
    text-decoration: none;
    padding: 14px 15px;
    border: 1px solid #FFF;
    border-radius: 5px;
    font-size: 18px;
    color:#FFF;
    font-weight: 400;
    position: absolute;
    right: 20px;
    top: 140px;
    :hover,
    :focus {
        color: #FF5A3E;
        text-decoration: none;
        border: 1px solid #FF5A3E;
    }
    
`
const NewsRightNavLink = styled(NavLink)`
    font-size: 24px;
    line-height: 100px;
    color: #fff;
    :hover,
    :focus {
        font-size: 26px;
        color: #fff;
    }
`
const NewsShow = styled.div`
    width: 384px;
    height: 162px;
    background: url(${news_show_1}) no-repeat;
    background-size: 100%;
    p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
`
const NewsBoxCenter = styled.div`
    width: 384px;
    height: 280px;
    box-sizing: border-box;
    background: #26334F;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 15px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 350px;
    `};
`
const NewList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 17px 0;
    p{
        font-size: 14px;
        font-weight: 400;
        color: #676D7A;
    }
`
const H6Link = styled(NavLink)`
    font-weight: 300;
    color: #fff;
    width: 82%;
    overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
    cursor: pointer;
    :hover,
    :focus {
        color: #FF5A3E;
    }
`
const Round = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #FF5A3E;
`
const NewsBoxRight = styled.div`
    width: 384px;
    height: 280px;
    background: #FF5A3E;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 350px;
    `};
`
const AboutColorBackground = styled.div`
    width: 100%;
    height: 730px;
    box-sizing: border-box;
    padding: 125px 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Microsoft YaHei;
`
const AboutStyles = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        justify-content: center;
    `};
`
const AboutShowImages = styled.div`
    width: 542px;
    height: 476px;
    background: url(${about_show_1}) no-repeat;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: none;
    `};
`
const AboutShowText = styled.div`
    width: 520px;
    height: 470px;
    padding: 20px;
    h2{
        font-size: 36px;
        font-weight: bold;
        color: #26334F;
        text-align: center;
        span{
            font-size: 48px;
            color: #FF5A3E;
        }
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #26334F;
        line-height: 28px;
        text-indent: 30px;
        margin-top: 45px;
        margin-bottom: 45px;
    }
`
const AbouNavLink = styled(NavLink)`
    outline: none;
    cursor: pointer;
    text-decoration: none;
    padding: 15px 35px;
    border: 1px solid #26334F;
    border-radius: 25px;
    font-size: 18px;
    color:#26334F;
    font-weight: 400;
    :hover,
    :focus {
        color: #FF5A3E;
        text-decoration: none;
        border: 1px solid #FF5A3E;
    }
`
const BusinessColorBackground = styled.div`
    width: 100%;
    height: 820px;
    box-sizing: border-box;
    padding: 68px 0;
    background: url(${business_background}) no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const BusinessStyles = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
    h2{
        display: block;
        font-size: 36px;
        padding: 0px 10px 30px 10px;
        font-weight: bold;
        color: #26334F;
        border-bottom: 2px solid #FF5A3E;
    }
    h6{
        font-size: 18px;
        font-weight: 400;
        color: #26334F;
        line-height: 27px;
        padding-top: 30px;
    }
`
const BusinessSelcetStyles = styled.div`
    width: 1180px;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`

const BusinessBox = styled.div`
    width: 220px;
    height: 110px;
    background: #ECF0F8;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        margin-bottom: 20px;
    `};
`
const IconAfter = styled.div<{active:boolean}>`
    width: 43px;
    height: 22px;
    background: url(${icon_6}) no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: -22px;
    display: ${({ active }) => (active ? 'black' : 'none')};
`
const Icon = styled.div<{active:boolean}>`
    width: 60px;
    height: 60px;
    border-radius: 30px;
    box-sizing: border-box;
    background-color: ${({ active }) => (active ? '#FF5A3E' : '#6D7689')};
    text-align:center;
    padding-top: 15px;
`
const IconTitle = styled.h3`
    font-size: 20px;
    font-weight: 400;
    color: #26334F;
    padding-left: 15px;
`




export default function Home() {

    const [showType, setShowType] = useState(1);
    const [list, setList] = useState<any[]>([]);
    const { i18n  } = useTranslation();


    const { t } = useTranslation();

    useEffect(() => {
        axios.get('./news/index.json')
        .then(function(res){
            const b = res.data.list.slice(0,5);
            setList(b)
        })
        .catch(function(error){
            console.log(error)
        })
    }, [])

    return (
        <HomeWapper>
            <HeaderPublicity>
                <AppBackgroundBody height='860px'>
                    <DeclarationText>
                        <h1>{t('Home_Title_1')}</h1>
                        <p>{t('Home_Title_2')}</p>
                        <DeclarationNavLink to={'nft'}>{t('Home_Title_3')}</DeclarationNavLink>
                    </DeclarationText>
                </AppBackgroundBody>
                <NewsColorBackground />
                <NewsStyles>
                    <NewsBoxLeft>
                        <NewsShow/>
                        <p>{list.length > 0 ? i18n.language === 'en' ? list[0].title_en : list[0].title_zh : ''}</p>
                        <NewsNavLink to={'news'}>→</NewsNavLink>
                    </NewsBoxLeft>
                    <NewsBoxCenter>
                        {
                            list.length > 0 &&
                            list.map((item, index) => {
                                return (
                                    <NewList key={index}>
                                        <Round />
                                        <H6Link to={'news'}>{i18n.language === 'en' ? item.title_en : item.title_zh }</H6Link>
                                        <p>{item.time_day}</p>
                                    </NewList>
                                )
                            })
                        }
                    </NewsBoxCenter>
                    <NewsBoxRight>
                        <img src={news_show_icon_1} alt="" />
                        <NewsRightNavLink to={'news'}>{t('Home_News_1')}→</NewsRightNavLink>
                    </NewsBoxRight>
                </NewsStyles>
            </HeaderPublicity>
            <AboutColorBackground>
                <AboutStyles>
                    <AboutShowImages />
                    <AboutShowText>
                        <h2>“{t('Name')}<span>{t('About_Title_1')}</span> ”</h2>
                        <p>{t('About_Text_1')}</p>
                        <p>{t('About_Text_2')}</p>
                       <AbouNavLink to={'/about'}>{t('Home_About_1')} →</AbouNavLink>
                    </AboutShowText>
                </AboutStyles>
            </AboutColorBackground>
            <BusinessColorBackground>
                <BusinessStyles>
                    <h2>{t('Business')}</h2>
                    <h6>Business introduction</h6>
                </BusinessStyles>
                <BusinessSelcetStyles>
                    <BusinessBox onClick={ () => setShowType(1) }>
                        <Icon active={ showType === 1 }>
                            <img src={icon_1} alt="" />
                        </Icon>
                        <IconTitle>{t('Business_Title_1')}</IconTitle>
                        <IconAfter active={showType === 1}/>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setShowType(2) }>
                        <Icon active={showType === 2}>
                            <img src={icon_2} alt="" />
                        </Icon>
                        <IconTitle>{t('Business_Title_2')}</IconTitle>
                        <IconAfter active={showType === 2}/>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setShowType(3) }>
                        <Icon active={showType === 3}>
                            <img src={icon_3} alt="" />
                        </Icon>
                        <IconTitle>{t('Business_Title_3')}</IconTitle>
                        <IconAfter active={showType === 3}/>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setShowType(4) }>
                        <Icon active={showType === 4}>
                            <img src={icon_4} alt="" />
                        </Icon>
                        <IconTitle>{t('Business_Title_4')}</IconTitle>
                        <IconAfter active={showType === 4}/>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setShowType(5) }>
                        <Icon active={showType === 5}>
                            <img src={icon_5} alt="" />
                        </Icon>
                        <IconTitle>{t('Business_Title_5')}</IconTitle>
                        <IconAfter active={showType === 5}/>
                    </BusinessBox>
                </BusinessSelcetStyles>
                <BusinessBoxShow type={showType}/>
            </BusinessColorBackground>
        </HomeWapper>
    )
}
