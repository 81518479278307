import React from 'react'
import styled from 'styled-components'

const ShadowWapper = styled.div<{active:boolean}>`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color:${({ theme }) => theme.black};
    transition: opacity 0.4s ease 0s;
    opacity: 0.6;
    z-index: 99;
    pointer-events: initial;
    display: ${({active }) => (active ? 'block' : 'none')};
`

const ModalWapper = styled.div<{active:boolean}>`
    width: 600px;
    max-height: 100vh;
    background: linear-gradient(0deg,#1F2129,#232732);
    border-radius: 10px;
    box-sizing:border-box;
    z-index: 100;
    position: fixed;
    top: 0px;
    left:calc(50% - 300px);
    display: ${({active }) => (active ? 'block' : 'none')};
    padding: 20px 0 0 0;
    animation: myfirst 2s;
    @keyframes myfirst
    {
        0%   { top:0px;}
        25%  { top:50px;}
        50%  { top:50px;}
        75%  { top:0px;}
        100% { top:0px;}
    }   

`


interface ModalProps {
    isOpen: boolean;
    children?: React.ReactNode;
}

export default function Model({
    isOpen, 
    children,
}: ModalProps) {
    return (
        <div>
            <ShadowWapper active={isOpen}/>
            <ModalWapper active={isOpen}>
                {children}
            </ModalWapper>
        </div>
    )
}
