import React, { memo, useState } from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledButton = styled.button`
    font-size:14px;
    width: 88px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    white-space: nowrap;
    background: #FF5A3E;
    border-radius: 20px;
    cursor: pointer;
    color:#FFF;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        font-size: 22px;
    `};
`;


export default memo(function SetLanguage() {
    const [colorKey, setColorKey] = useState('English')
    const { i18n  } = useTranslation();

    const langClick = () => {
        if(i18n.language === 'zh'){
            i18n.changeLanguage('en')
            setColorKey('中文')
        }else{
            i18n.changeLanguage('zh')
            setColorKey('English')
        }
    }

    return (
        <StyledButton onClick={langClick}>{colorKey}</StyledButton>
    )
}) 