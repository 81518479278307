import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const BusinessTextShowWapper = styled.div`
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    font-family: Microsoft YaHei;

    h2{
        font-size: 24px;
        font-weight: bold;
        color: #26334F;
        line-height: 36px;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        color: #26334F;
        line-height: 36px;
        text-indent: 36px;
    }
`
const TextHeader = styled.div`
    min-height: 500px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        min-height: 600px;
    `};
`

export default function BusinessTextShow({type}:{type:number}) {

    const { t } = useTranslation();

    if(type === 1){
        return (
            <BusinessTextShowWapper>
                <TextHeader>
                    <h2>{t('Business_Title_6')}</h2>
                    <p>{t('Business_Fund_0')}</p>
                    <p>{t('Business_Fund_1')}</p>
                    <p>{t('Business_Fund_2')}</p>
                    <p>{t('Business_Fund_3')}</p>
                </TextHeader>
            </BusinessTextShowWapper>
        )
    }else if(type === 2){
        return (
            <BusinessTextShowWapper>
                <TextHeader>
                    <h2>{t('Business_Title_6')}</h2>
                    <p>{t('Business_Equity_1')}</p>
                    <p>{t('Business_Equity_2')}</p>
                    <p>{t('Business_Equity_3')}</p>
                    <p>{t('Business_Equity_4')}</p>
                </TextHeader>
            </BusinessTextShowWapper>
        )
    }else if(type === 3){
        return (
            <BusinessTextShowWapper>
                <TextHeader>
                    <h2>{t('Business_Title_6')}</h2>
                    <p>{t('Business_Asset_1')}</p>
                </TextHeader>
            </BusinessTextShowWapper>
        )
    }else if(type === 4){
        return (
            <BusinessTextShowWapper>
                <TextHeader>
                    <h2>{t('Business_Title_6')}</h2>
                    <p>{t('Business_Property_1')}</p>
                </TextHeader>
            </BusinessTextShowWapper>
        )
    }else{
        return (
            <BusinessTextShowWapper>
                <TextHeader>
                    <h2>{t('Business_Title_6')}</h2>
                    <p>{t('Business_Blockchain_1')}</p>
                </TextHeader>
            </BusinessTextShowWapper>
        )
    }
}
