import React from 'react';
import styled from 'styled-components';
import AssetShow_images from '../../assets/images/AssetShowImages.jpg';
import { conversionDate, giveAwayBehalf, numberParseChina } from '../../utils/tool';




const AssetShowImagesWapper = styled.div`
    width:661px;
    height: 473px;
    background: url(${AssetShow_images}) no-repeat;
    background-size: 100%;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    font-family: 'STKaiti';
    color: #635d5b;
    p:nth-child(1){
        position: absolute;
        top: 233px;
        left: 180px;
    }
    p:nth-child(2){
        position: absolute;
        top: 263px;
        left: 180px;
    }
    p:nth-child(3){
        position: absolute;
        top: 270px;
        left: 485px;
    }
    p:nth-child(4){
        position: absolute;
        top: 292px;
        left: 180px;
    }
    p:nth-child(5){
        position: absolute;
        top: 322px;
        left: 180px;
    }
    p:nth-child(6){
        position: absolute;
        top: 312px;
        left: 485px;
    }
    p:nth-child(7){
        position: absolute;
        top: 352px;
        left: 180px;
    }
    p:nth-child(8){
        position: absolute;
        bottom: 47px;
        right: 210px;
    }
    p:nth-child(9){
        position: absolute;
        bottom: 47px;
        right: 80px;
    }
`
interface AssetShowImagesProps {
    id:number,
    tokenId:string,
    name:string,
    socialSecurityNumber:string,
    amount:string,
    typeId:string,
    price:string,
    dateOfCreation:string,
}

export default function AssetShowImages({
    id,
    tokenId,
    name,
    socialSecurityNumber,
    amount,
    typeId,
    price,
    dateOfCreation,
}:AssetShowImagesProps) {
    const setTypeName = (typeId:string) => {
        if(typeId === '0'){
            return '代持'
        }else if(typeId === '1'){
            return '分红'
        }
    }

    const giveNumber = giveAwayBehalf(typeId,price);

    const viewShares = (tokenId:string, typeId:string, price:string) => {
        // console.log(typeId + "," + tokenId)
        if (Number(typeId) > 0 && Number(typeId) < 2 && Number(tokenId) >= 227290) {
            return <>{`优惠赠送${numberParseChina(Number(amount) * 2)}股`}</>;
        } else if (Number(typeId) < 2 && Number(tokenId) >= 130000) {
            if (Number(price) >= 50000) {
                return <>{`优惠赠送${numberParseChina(Number(price) * 2)}股`}</>;
            }
            return <>{`优惠赠送${numberParseChina(Number(amount) / 2)}股`}</>;
        } else {
            // 372801197410071029
            if (Number(typeId) > 0 && Number(tokenId) >= 227290) {
                return <>{`优惠赠送${numberParseChina(Number(amount) * 5)}股`}</>;
            } else if (Number(tokenId) >= 171308) {
                if (Number(amount) >= 5000000) {
                    return <>{`优惠赠送${numberParseChina(Number(amount) * 3)}股`}</>;
                }
                return <>{`优惠赠送${numberParseChina(Number(amount) * 2)}股`}</>;
            }
            return <>{`优惠赠送${numberParseChina(Number(amount))}股`}</>;
        }
    }

    return (
        <AssetShowImagesWapper id={'node_'+id}>
            <p>{name}</p>
            <p>{socialSecurityNumber}</p>
            <p>{
                numberParseChina(
                    Number(typeId) > 0 && Number(tokenId) >= 227290
                        ? (typeId === '1' ? Number(amount) * 2.5 : Number(amount) * 5)
                            : Number(tokenId) >= 171308 && typeId === '2' ? (Number(amount) >= 5000000 ? Number(amount) * 3 : Number(amount) * 2) : Number(amount)
                    // Number(tokenId) >= 171308 && typeId === '2'
                    //     ? Number(amount) >= 5000000 ? Number(amount) * 3 : Number(amount) * 2
                    //     : Number(amount)
                )
            }</p>
            <p>壹元/股</p>
            <p>{typeId !== '2' && `${setTypeName(typeId)}预购${numberParseChina(Number(price))}`}</p>
            <p>{conversionDate( Number(dateOfCreation))}</p>
            <p>{
                Number(tokenId) >= 130000
                ? viewShares(tokenId, typeId , price)
                : (typeId === '2' ? `优惠赠送${numberParseChina(Number(amount))}股` : giveNumber !== 0 && `优惠赠送${numberParseChina(Number(giveNumber))}股`)
            }</p>
            <p>{tokenId}</p>
            <p>{tokenId}</p>
        </AssetShowImagesWapper>
    )
}
