import About from '../pages/about';
import Business from '../pages/business';
import Home from '../pages/home'
import News from '../pages/news';
import Nft from '../pages/nft';
import NftIntroduce from '../pages/nft_Introduce';

const routes = [
    {
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: '/home',
        exact: true,
        component: Home
    },
    {
        path: '/business',
        exact: true,
        component: Business
    },
    {
        path: '/news',
        exact: true,
        component: News
    },
    {
        path: '/nftIntroduce',
        exact: true,
        component: NftIntroduce
    },
    {
        path: '/nft',
        exact: true,
        component: Nft
    },
    {
        path: '/about',
        exact: true,
        component: About
    }
]

export default routes;