import Pool  from "./abis/Pool.json";

//链ID
export const CHAIN_ID = 56;

export const RPC_URL = 'https://bscrpc.com';
export const HTTP_URL = 'https://zitian.on.fleek.co/';

// export const Old_Pool_Address = '0x0ad9f01c3A09743acfA76871625BC88bf51532E9';
export const Pool_Address = '0x291297Ea3FBF71Bf7Ce66Ec84cC40C8669a31D92';

export const Pool_ABI:any = Pool;
// 330823197103041128
