
import React, { useState } from 'react';
import styled from 'styled-components';
import AppBackgroundBody from '../../components/AppBackgroundBody';

import business_show_6 from "../../assets/images/business_show_6.png";
import business_show_7 from "../../assets/images/business_show_7.png";
import business_show_8 from "../../assets/images/business_show_8.png";
import business_show_9 from "../../assets/images/business_show_9.png";
import business_show_10 from "../../assets/images/business_show_10.png";
import BusinessTextShow from '../../components/BusinessTextShow';
import { useTranslation } from 'react-i18next';



const BusinessWapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const BusinessColorBackground = styled.div`
    width: 100%;
    height: 623px;
    background: #FFF;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        height: 0px;
    `};
`
const BusinessSelectStyles = styled.div`
    width: 1200px;
    height: 220px;
    margin: 0 auto;
    margin-top: 80px;
    background-color: rgba( 255 , 255 , 255 , 0.08 );
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        height: 70px;
        margin-top: 301px;
    `};
`
const BusinessBox = styled.div`
    width: 230px;
    box-sizing: border-box;
    padding: 20px 40px;
    cursor: pointer;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 120px;
        padding: 0px 0px;
    `};
    img{
        ${({ theme }) => theme.mediaWidth.upToLarge`
            display: none;
        `};
    }
`
const Title = styled.div<{active:boolean}>`
    width: 150px;
    height: 70px;
    font-size: 20px;
    font-weight: 400;
    line-height: 70px;
    text-align:center;
    background-color: ${({ active }) => (active ? '#FFF' : 'none')};
    color: ${({ active }) => (active ? '#26334F' : '#fff')};;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 120px;
    `};
`
const BusinessTextStyles = styled.div `
    width: 1200px;
    min-height: 668px;
    background: #FFFFFF;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    position: absolute;
    top: 370px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        min-height: 1050px;
        width: 750px;
        height: 768px;
    `};
`

export default function Business() {

    const [businessType, setBusinessType] = useState(1);
    const { t } = useTranslation();

    
    return (
        <BusinessWapper>
            <AppBackgroundBody height='588px'>
                <BusinessSelectStyles>
                    <BusinessBox onClick={ () => setBusinessType(1) }>
                        <img src={business_show_6} alt="" />
                        <Title active={businessType === 1}>{t('Business_Title_1')}</Title>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setBusinessType(2) }>
                        <img src={business_show_7} alt="" />
                        <Title active={businessType ===2}>{t('Business_Title_2')}</Title>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setBusinessType(3) }>
                        <img src={business_show_8} alt="" />
                        <Title active={businessType === 3}>{t('Business_Title_3')}</Title>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setBusinessType(4) }>
                        <img src={business_show_9} alt="" />
                        <Title active={businessType === 4}>{t('Business_Title_4')}</Title>
                    </BusinessBox>
                    <BusinessBox onClick={ () => setBusinessType(5) }>
                        <img src={business_show_10} alt="" />
                        <Title active={businessType === 5}>{t('Business_Title_5')}</Title>
                    </BusinessBox>
                </BusinessSelectStyles>
            </AppBackgroundBody>
            <BusinessColorBackground />
            <BusinessTextStyles>
                <BusinessTextShow type = {businessType}/>
            </BusinessTextStyles>
        </BusinessWapper>
    )
}
