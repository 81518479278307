import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import introduce_show_1 from "../../assets/images/introduce_show_1.png";
import introduce_show_2 from "../../assets/images/introduce_show_2.png";



const NftIntroduceWapper = styled.div`
    width: 100%;
    font-family: Microsoft YaHei;

`
const NftWihteBackground = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const NftBlueBackground = styled.div`
    width: 100%;
    background-color: #F8FAFF;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const IntroduceHeader = styled.div`
    width: 1200px;
    height: 610px;
    box-sizing: border-box;
    padding: 130px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        justify-content: center;
    `};
    img{
        ${({ theme }) => theme.mediaWidth.upToLarge`
            display: none;
        `};
    }
    
`
const IntroduceText = styled.div`
    width: 550px;
    height: 400px;
    h2{
        font-size: 42px;
        font-weight: bold;
        color: #26334F;
        text-align: center;
        margin-bottom: 30px;
        span{
            color: #FF5A3E;
        }
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #26334F;
        line-height: 36px;
        text-indent: 36px;
    }
`
const IntroduceCenter = styled.div`
    width: 1200px;
    height: 460px;
    box-sizing: border-box;
    padding: 80px 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        justify-content: center;
    `};
    h3{
        font-size: 42px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #26334F;
        line-height: 36px;
        padding-bottom: 30px;
        border-bottom: 2px solid #FF5A3E;
        margin-bottom: 70px;
    }
    p{
        font-size: 18px;
        font-weight: 300;
        color: #26334F;
        line-height: 42px;
        text-align:center;
    }
`
const IntroduceFooter = styled.div`
    width: 1200px;
    height: 500px;
    box-sizing: border-box;
    padding: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        justify-content: center;
    `};
    img{
        ${({ theme }) => theme.mediaWidth.upToLarge`
            display: none;
        `};
    }
`
const IntroduceFooterText = styled.div`
    width: 700px;
    height: 336px;
    box-sizing: border-box;
    padding: 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 6px 7px 0px rgba(26, 28, 36, 0.08);
    font-size: 16px;
    font-weight: 300;
    color: #26334F;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        display: block;
        margin-bottom: 20px;
    }
`

export default function NftIntroduce() {

    const { t } = useTranslation();

    return (
        <NftIntroduceWapper>
            <NftWihteBackground>
                <IntroduceHeader>
                    <IntroduceText>
                        <h2>{t('Introduction_Title_1')}<span>{t('Introduction_Title_2')}</span></h2>
                        <p>{t('Introduction_Text__1')}</p>
                        <p>{t('Introduction_Text__2')}</p>
                    </IntroduceText>
                    <img src={introduce_show_1} width='500px' height='336px' alt="" />
                </IntroduceHeader>
            </NftWihteBackground>
            <NftBlueBackground>
                <IntroduceCenter>
                    <h3>{t('Introduction_Title_3')}</h3>
                    <p>{t('Introduction_Text__3')}</p>
                </IntroduceCenter>
            </NftBlueBackground>
            <NftWihteBackground>
                <IntroduceFooter>
                    <img src={introduce_show_2} width='500px' height='336px' alt="" />
                    <IntroduceFooterText>
                        <p>{t('Introduction_Text__4')}</p>
                        <p>{t('Introduction_Text__5')}</p>
                    </IntroduceFooterText>
                </IntroduceFooter>
            </NftWihteBackground>
        </NftIntroduceWapper>
    )
}
