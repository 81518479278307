import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import business_show_1 from "../../assets/images/business_show_1.png";
import business_show_2 from "../../assets/images/business_show_2.png";
import business_show_3 from "../../assets/images/business_show_3.png";
import business_show_4 from "../../assets/images/business_show_4.png";
import business_show_5 from "../../assets/images/business_show_5.png";
import business_show_1_en from "../../assets/images/business_show_1_en.png";
import business_show_2_en from "../../assets/images/business_show_2_en.png";
import business_show_3_en from "../../assets/images/business_show_3_en.png";
import business_show_4_en from "../../assets/images/business_show_4_en.png";
import business_show_5_en from "../../assets/images/business_show_5_en.png";




const BoxShowOne = styled.div<{active:boolean}>`
    width: 1180px;
    height: 334px;
    box-sizing: border-box;
    border:4px solid #FFF;
    background: ${({ active }) => (active ? `url(${business_show_1_en}) no-repeat` : `url(${business_show_1}) no-repeat`)};
    background-size: 100%;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 708px;
        height: 200px;
    `};
`
const BoxShowTwo = styled(BoxShowOne)`
    background: ${({ active }) => (active ? `url(${business_show_2_en}) no-repeat` : `url(${business_show_2}) no-repeat`)};
    background-size: 100%;
`
const BoxShowThree = styled(BoxShowOne)`
    background: ${({ active }) => (active ? `url(${business_show_3_en}) no-repeat` : `url(${business_show_3}) no-repeat`)};
    background-size: 100%;
`
const BoxShowFour= styled(BoxShowOne)`
    background: ${({ active }) => (active ? `url(${business_show_4_en}) no-repeat` : `url(${business_show_4}) no-repeat`)};
    background-size: 100%;
`
const BoxShowFive = styled(BoxShowOne)`
    background: ${({ active }) => (active ? `url(${business_show_5_en}) no-repeat` : `url(${business_show_5}) no-repeat`)};
    background-size: 100%;
`

interface BusinessBoxShowProps {
    type: number,
}

export default function BusinessBoxShow({
    type
}:BusinessBoxShowProps) {

    const { i18n  } = useTranslation();

    if(type === 1){
        return (
            <BoxShowOne active={i18n.language === 'en'}/>
        )
    }else if(type === 2){
        return (
            <BoxShowTwo active={i18n.language === 'en'}/>
        )
    }else if(type === 3){
        return (
            <BoxShowThree active={i18n.language === 'en'}/>
        )
    }else if(type === 4){
        return (
            <BoxShowFour active={i18n.language === 'en'}/>
        )
    }else{
        return (
            <BoxShowFive active={i18n.language === 'en'}/>
        )
    }
}
