import React from 'react';
import styled from 'styled-components';
import AppBackgroundBody from '../../components/AppBackgroundBody';

import about_show_1 from "../../assets/images/about_show_1.png";
import { useTranslation } from 'react-i18next';
     

const AboutWapper = styled.div`
    width: 100%;
    min-height: 1300px;
    background-color: #fff;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        min-height: 1600px;
    `};
`
const AboutHeader = styled.div`
    width: 1200px;
    margin: 0  auto;
    height: auto;
    position: relative;
    box-sizing: border-box;
    padding: 55px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: flex;
        flex-direction: column;
        width: 750px;
        justify-content: center;
        align-items: center;
        padding-top: 400px;
    `};
`
const HeaderText = styled.div`
    width: 500px;
    box-sizing: border-box;
     h2{
        font-size: 36px;
        font-weight: bold;
        color: #FFF;
        span{
            font-size: 48px;
            color: #FF5A3E;
        }
    }
    p{
        width: 500px;
        font-size: 14px;
        font-weight: 400;
        color: #FFF;
        line-height: 28px;
        text-indent: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
`
const AboutShowImages = styled.div`
    width: 542px;
    height: 476px;
    background: url(${about_show_1}) no-repeat;
    position: absolute;
    right: 0;
    top: 55px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        display: none;
    `};
`
const AnoutText =styled.div`
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 120px 30px 50px 30px; 
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #26334F;
    line-height: 44px;
    text-indent: 36px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
    `};
`

export default function About() {
    
    const { t } = useTranslation();

    return (
        <AboutWapper>
            <AppBackgroundBody height='470px'>
                <AboutHeader>
                    <HeaderText>
                        <h2>“{t('Name')}<span>{t('About_Title_1')}</span>”</h2>
                        <p>{t('About_Text_1')}</p>
                        <p>{t('About_Text_2')}</p>
                    </HeaderText>
                    <AboutShowImages />
                </AboutHeader>
            </AppBackgroundBody>
            <AnoutText>
                <p>{t('About_Text_3')}</p>
                <p>{t('About_Text_4')}</p>
                <p>{t('About_Text_5')}</p>
                <p>{t('About_Text_6')}</p>
                <p>{t('About_Text_7')}</p>
                <p>{t('About_Text_8')}</p>
            </AnoutText>
        </AboutWapper>
    )
}
