import React from 'react'
import styled from 'styled-components';
import icon_email from "../../assets/images/icon_email.png";
import icon_address from "../../assets/images/icon_address.png";
import icon_phone from "../../assets/images/icon_phone.png";
import { useTranslation } from 'react-i18next';

const FooterWapper = styled.div`
    width: 100%;
    height: 140px;
    box-sizing: border-box;
    padding: 40px 0;
    background: #1A253E;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #838C9E;
`
const FooterStyles = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    .divider{
        border-right: 1px solid #6D7689;
    }
`
const FooterBox = styled.div`
    min-width: 140px;
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    p{
        margin-left: 10px;
    }
`

export default function Footer() {

    const { t } = useTranslation();

    return (
        <FooterWapper>
            <FooterStyles>
                <FooterBox className='divider'>
                    <img src={icon_phone} alt="" />
                    <p>{t('Foonter_Telephone')}: +1(262)726-1199</p>
                </FooterBox>
                <FooterBox className='divider'>
                    <img src={icon_address} alt="" />
                    <p>{t('Foonter_Address')}: 1482 Fulton St, Brooklyn, NY</p>
                </FooterBox>
                <FooterBox>
                    <img src={icon_email} alt="" />
                    <p>{t('Foonter_Mail')}: service@id-fund.net</p>
                </FooterBox>
            </FooterStyles>
        </FooterWapper>
    )
}
