import React from 'react';
import styled from 'styled-components';
import hero_background from "../../assets/images/hero_background.png";

const BackgroundBodyWapper = styled.div`
    position: relative;
    width: 100%;
    background: url(${hero_background}) no-repeat;
    background-size: cover;
    background-position: center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        width: 750px;
        min-height:calc(100vh - 225px);
    `};
`

export default function AppBackgroundBody({ children,height }: { children: React.ReactNode,height:string }) {

    return (
        <BackgroundBodyWapper style={{height:height}}>
            {children}
        </BackgroundBodyWapper>
    )
}
