import * as actionTypes from './constants';




export const SetLoading = (res:Object) =>({
    type:actionTypes.SET_LOADING,
    isLoading:res
})

export const SaveAddress = (res:string) =>({
    type:actionTypes.SAVE_ADDRESS,
    address:res
})

export const SaveSecurityNumber = (res:string) =>({
    type:actionTypes.SAVE_SECURIT_NUMBER,
    securityNumber:res
})
